export const hasFiles = (
  data: Record<string, string> | File | Blob | FileList | string,
) => {
  if (data instanceof FormData) return false;

  return (
    data instanceof File ||
    data instanceof Blob ||
    data instanceof FileList ||
    (typeof data === 'object' &&
      data !== null &&
      Object.values(data).find((value) => hasFiles(value)) !== undefined)
  );
};

const getKey = (parent: string | null, property: string) =>
  parent ? `${parent}[${property}]` : property;

const appendToFormData = (formData, key, value) => {
  if (value instanceof Date) return formData.append(key, value.toISOString());

  if (value instanceof File) return formData.append(key, value, value.name);

  if (value instanceof Blob) return formData.append(key, value);

  if (typeof value === 'boolean')
    return formData.append(key, value ? '1' : '0');

  if (value === null) return formData.append(key, '');

  if (typeof value !== 'object') return formData.append(key, value);

  objectToFormData(value, formData, key);
};

export const objectToFormData = (
  object: Record<string, unknown> | Blob | string | null,
  formData = new FormData(),
  parent = '',
) => {
  if (object === null || object === 'undefined' || object.length === 0)
    return formData.append(parent, object);

  Object.keys(object).forEach((key) => {
    appendToFormData(formData, getKey(parent, key), object[key]);
  });

  return formData;
};
