export const CARGO_BOOKING_STATUS = {
  APPROVED: 'approved',
  VESSEL_NOMINATED: 'vessel nominated',
  CANCELLED: 'cancelled',
  UNDER_DISCUSSION: 'under discussion',
  DECLARED: 'declared',
  PENDING_G2O_REVIEW: 'pending_g2o_review',
  PENDING_CUSTOMER_REVIEW: 'pending_customer_review',
  NOMINATED: 'nominated',
} as const;

export const MESSAGE_TYPE = {
  TEXT: 'text',
  NEW: 'new_nomination',
  APPROVED: 'approved_nomination',
  UPDATED: 'updated_nomination',
  CONFIRMED_UPDATES: 'confirmed_nomination_updates',
  DECLARATION_UPDATED: 'updated_declaration',
} as const;

export const CARGO_PERIOD_TYPE = {
  BINDING: 'binding',
  FIXED: 'fixed',
  EXPECTED: 'expected',
} as const;

export const ERROR_PRIORITIES = {
  missing: 1,
  does_not_exist: 2,
  start_date_after_end: 2,
  date_in_past: 2,
  date_beyond_limit: 2,
  quantity_mismatch: 2,
  trade_port_mismatch: 2,
  laycan_bigger_than: 2,
  laycan_smaller_than: 2,
  limited_vessel_selection: 2,
  cant_change: 3,
} as const;

export const ASCII_A = 65;

export const BOOKING_PANES = {
  DECLARATION_VIEW: 'declaration_view',
  DECLARATION_CREATE: 'declaration_create',
  NOMINATION_VIEW: 'nomination_view',
  NOMINATION_CREATE: 'nomination_create',
} as const;

export const DOWNLOAD_TOOLTIP_TEXT = {
  DECLARATION: 'Declaration is not uploaded yet.',
  NOMINATION: 'No active Nominations yet.',
} as const;

export const CARGO_BOOKING_STATUS_DECLARATION = {
  INQUIRY: 'inquiry',
  CONFIRMED: 'confirmed',
  WITHDRAWN: 'withdrawn',
  OPPORTUNITY: 'opportunity',
  OFFER: 'offer',
  TARGETED: 'targeted',
} as const;

export const CARGO_BOOKING_DECLARATION_MONTHS = {
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
} as const;
