import type { DataEntry, IOption } from '~/types';

export function addIf<T>(condition: boolean, item: T): [T] | [] {
  return condition ? [item] : [];
}

export const addObjectIf = <T extends object>(condition: boolean, item: T) =>
  condition ? item : [];

export const isEmpty = <T>(
  value: T | undefined | null | boolean,
): value is null | undefined | boolean =>
  value === undefined ||
  value === null ||
  value === false ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const isObject = (obj: unknown) =>
  typeof obj === 'object' && !Array.isArray(obj) && obj !== null;

export const getRandomId = () =>
  (Date.now().toString(36) + Math.random().toString(36).substr(2, 5))
    .toUpperCase()
    .toLowerCase();

// this converts a value to a number, or preserves it in case it's null
export const convertToNumber = (value: string | null) =>
  value ? +value : value;

export const capitalize = (text: string | unknown) => {
  if (typeof text !== 'string') {
    return '';
  }

  return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
};

export const toTitleCase = (text?: string) =>
  text
    ? text
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())
        .join(' ')
    : '';

export const removeUnderscore = (text?: string) =>
  text?.split('_').join(' ') ?? '';

export const removeNullProperties = (obj: object) =>
  Object.keys(obj).reduce((acc, key) => {
    if (isEmpty(obj[key])) {
      return acc;
    }

    return {
      ...acc,
      [key]: obj[key],
    };
  }, {});

const convertRawDate = (rawDate: string | Date) =>
  typeof rawDate === 'string' ? new Date(rawDate) : rawDate;

export const removeTimezoneOffset = (rawDate?: string | null) => {
  if (!rawDate) {
    return null;
  }

  const date = convertRawDate(rawDate);

  const offset = date.getTimezoneOffset() * 60 * 1000;
  const validTimestamp = date.getTime() - offset;

  return new Date(validTimestamp);
};

export const convertDateToIso = (rawDate?: Date | string | null) => {
  if (!rawDate) {
    return null;
  }

  const date = convertRawDate(rawDate);

  const iso = date.toISOString();
  return iso.substring(0, iso.indexOf('T'));
};

export const removeDuplicates = (arr1: object[], arr2: object[], key = 'id') =>
  arr1.filter((aa) => !arr2.find((bb) => aa[key] === bb[key])).concat(arr2);

export const checkDirty = (defaultForm, currentForm, getFormData) =>
  JSON.stringify(getFormData(defaultForm)) ===
  JSON.stringify(getFormData(currentForm));

export const getFullName = (
  firstName?: string | null,
  lastName?: string | null,
  fallback?: string | null,
) =>
  firstName && lastName
    ? `${firstName} ${lastName}`
    : firstName || lastName || fallback || '';

export const useNullableObjectSerializer = <T>() => ({
  read: (value: string | null): T => (value ? JSON.parse(value) : null),
  write: (value: T | null) => (value ? JSON.stringify(value) : ''),
});

export const debounce = <T>(func: (...args: T[]) => void, timeout = 300) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: T[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const sortOptionsAZ = <T extends IOption>(data: T[], key?: keyof T) =>
  data.sort((a, b) => {
    const valueA = a[key || ('name' as keyof T)] as string;
    const valueB = b[key || ('name' as keyof T)] as string;

    const textA = valueA.toLowerCase();
    const textB = valueB.toLowerCase();
    if (textA < textB) return -1;

    if (textA > textB) return 1;

    return 0;
  });

export const formatDataEntries = <T>({ entries, max, key }: DataEntry<T>) => {
  const visibleEntries = entries.slice(0, max);
  const hiddenEntriesCount = Math.max(0, entries.length - max);

  let formattedEntries = visibleEntries
    .map((entry) => {
      const value = entry[key];

      if (typeof value === 'string') {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }

      return value;
    })
    .join(', ');

  if (hiddenEntriesCount > 0) {
    formattedEntries += ` (+${hiddenEntriesCount})`;
  }

  return formattedEntries;
};

export const formatConstantsToOptions = ({
  entries,
  useTitleCase = false,
}: {
  entries: string[];
  useTitleCase?: boolean;
}): IOption[] => {
  return entries.map((entry) => ({
    id: entry,
    name: useTitleCase ? toTitleCase(entry) : entry,
  }));
};
